<script setup lang="ts">
import VShopSelect from "@magnit/unit-shop-select/src/VShopSelect.vue";
import type { IShopSelectShop } from "@magnit/unit-shop-select/src/shop-select.types";
import type { IStoreSearchStore } from "~/typings/api/storeSearch";

const emit = defineEmits<{
  "close:app-shop-select": [];
  "submit:app-shop-select": [store: IStoreSearchStore];
}>();

const {
  mapBounds,
  selectedShop,
  shopSearchQuery,
  mapLoading,
  listLoading,
  storesListRef,
  yandexMapKey,
  mapCenter,
  shopsFilterOptions,

  handleMapBoundsChange,
  handleSearchQueryChange,
} = useShopSelect();
const { submitSelectedStore, setStorePrompt } = useStoresStore();

const modalOpen = ref(true);

const handleShopSelectClose = () => emit("close:app-shop-select");
const handleShopSelectSubmit = async (payload: { shop: IShopSelectShop; }) => {
  mapLoading.value = true;
  try {
    submitSelectedStore(payload.shop as IStoreSearchStore);
    emit("submit:app-shop-select", payload.shop as IStoreSearchStore);
    handleShopSelectClose();
    setStorePrompt();
  } catch (err) {
    logError("Ошибка при попытке получения идентификатора магазина", { err });
    setStorePrompt("");
  } finally {
    mapLoading.value = false;
  }
};
</script>

<template>
  <VShopSelect
    v-model:modal-open="modalOpen"
    class="app-shop-select"
    :yandex-map-key="yandexMapKey"
    :map-loading="mapLoading"
    :shops-list-loading="listLoading"
    :bounding-box="mapBounds"
    :center="mapCenter"
    :shops="storesListRef"
    :shops-search-string="shopSearchQuery"
    :shops-filter-options="shopsFilterOptions"
    :selected-shop="selectedShop"
    @submit:bounding-box="handleMapBoundsChange"
    @submit:shops-search="handleSearchQueryChange"
    @submit:shop-select="handleShopSelectSubmit"
    @close:shop-select="handleShopSelectClose"
  />
</template>
